@import url("//cdn.web-fonts.ge/fonts/bpg-nino-mtavruli/css/bpg-nino-mtavruli.min.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "BPG Nino Mtavruli", sans-serif;
}

.eduschool-main {
  width: 100%;
  padding: 0 300px;
}

.eduschool-courseinfo-wrapper {
  display: flex;
  flex-direction: column;
}

.eduschool-courseinfo-mentor {
  display: flex;
  gap: 34px;
}

.eduschool-courseinfo-mentor2 {
  gap: 74px;
}

.eduschool-swiper-container {
  max-width: 521px;
  border-radius: 10px;
  margin: 0;
}

.eduschool-swiper-slide {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: 10px;
}

.eduschool-swiper-slide img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.mentor-swiper-title {
  min-width: 400px;
  color: #e2aa18;
  letter-spacing: 1.5px;
  font-size: 26px;
  margin-top: 58px;
}

.eduschool-course-prices {
  min-width: 354px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
}

.eduschool-course-title {
  font-size: 18px;
}

.eduschool-course-priceinfo {
  color: #e2aa18;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  gap: 40px;
}

.eduschool-goal {
  z-index: 10;
  max-width: 876px;
  min-height: 575px;
  padding: 29px 12px;
  background-image: linear-gradient(
    to right,
    #160e3d,
    #181845,
    #1a224d,
    #1e2c54,
    #23355b,
    #283c62,
    #2e4468,
    #344b6f,
    #3b5278,
    #415981,
    #48608a,
    #4f6793
  );
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-radius: 10px;
  box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  -webkit-box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  -moz-box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  margin-top: -350px;
}

.eduschool-goal-title {
  color: #ffffff;
  font-size: 32px;
  letter-spacing: 1.5px;
}

.eduschool-goal-description {
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 1.4px;
  margin-top: 52px;
  text-align: justify;
}

.eduschool-buttons-wrapper {
  display: flex;
  flex-direction: row;
  gap: 42px;
  margin-top: 22px;
  align-self: flex-end;
}

.educity-additional-modules-wrapper {
  margin-top: 78px;
}

.educity-additional-modules-title {
  color: #e2aa18;
  font-size: 32px;
  letter-spacing: 1.5px;
  text-align: center;
}

.educity-additional-modules {
  display: flex;
  justify-content: center;
  gap: 23px;
  margin-top: 68px;
}

.educity-additional-modules > div {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.educity-additional-module {
  display: flex;
  align-items: center;
  width: 100%;
  height: 92px;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.5px;
  background-image: linear-gradient(
    to right,
    #160e3d,
    #181845,
    #1a224d,
    #1e2c54,
    #23355b,
    #283c62,
    #2e4468,
    #344b6f,
    #3b5278,
    #415981,
    #48608a,
    #4f6793
  );
  border-radius: 10px;
  box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  -webkit-box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  -moz-box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  padding-left: 19px;
}
