@import url("//cdn.web-fonts.ge/fonts/bpg-nino-mtavruli/css/bpg-nino-mtavruli.min.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "BPG Nino Mtavruli", sans-serif;
  overflow: auto;
  min-height: 100%;
}

/* navbar styles */

.navbar-wrapper {
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 15px 250px;
  max-width: 100%;
  position: fixed;
  z-index: 100;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.navbar-logo {
  max-width: 210px;
  height: 90px;
  -webkit-filter: drop-shadow(2px 3px 1px #222);
  filter: drop-shadow(2px 3px 1px rgba(0, 0, 0, 0.5));
  cursor: pointer;
}

.navbar-link-wrapper {
  list-style-type: none;
  display: flex;
  gap: 40px;
  margin-top: 18px;
}

.navbar-link-wrapper li a {
  color: #302c2c;
  letter-spacing: 0.32px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
}

.navbar-link-wrapper li::after {
  display: block;
  content: "";
  width: 100%;
  height: 3px;
  background-color: #e2aa18;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  margin-top: 3px;
}

.navbar-link-wrapper li:hover:after {
  transform: scaleX(1);
}

.social-icons-wrapper {
  display: flex;
  gap: 11px;
}

.social-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
}

.social-icon > * {
  font-size: 35px !important;
  cursor: pointer;
}

.social-icon > *:hover {
  fill: #edc45c !important;
}

.mobile-menu-icon {
  display: none !important;
}

.mobile-menu-icon * {
  width: 40px !important;
  height: 40px !important;
  cursor: pointer;
}

/* header description styles and logo */

.header-info-wrapper {
  max-width: 100%;
  height: 747px;
  background-image: url("../../../Assets/Images/header-background.png");
  background-size: 100% 100%;
  background-attachment: fixed;
}

.header-info-margin-container {
  margin: 0 250px;
  margin-top: 200px;
  max-width: 1150px;
  width: 100%;
  display: flex;
}

.header-info-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 30px;
  padding: 10px 18px 22px 18px;
}

.header-info-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 38.08px;
  letter-spacing: 2%;
  color: #0e223d;
}

.header-info-text {
  max-width: 540px;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #807474;
}

.header-info-image {
  width: 378px;
  height: 354px;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

/* Partners Section Animation */

.Partner {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 44px;
  overflow: hidden;
}

.Partner h1 {
  color: #e2aa18;
  letter-spacing: 2%;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
}
.Partner p {
  color: #000000;
  letter-spacing: 2%;
  font-size: 16px;
  margin: 24px 0px 54px 0px;
  text-align: center;
}

.partnerIconContainer {
  display: grid;
  grid-auto-flow: column;
  gap: 50px;
  width: 90%;
  white-space: nowrap;
  animation: scrollRightToLeft 8s linear infinite;
}

.partnerIconContainer img {
  width: 166px;
  height: 157px;
}

/* animation for partners 1200px + */

@keyframes scrollRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}
/* Popular Courses section */

.sectionContainer {
  background-color: #f5f5f5;
}
.sectionTitle {
  color: #e2aa18;
  font-size: 30px;
  letter-spacing: 2%;
  font-weight: 700;
  padding-top: 35px;
  margin-bottom: 64px;
}

.CourseFullWrapper {
  background-color: #f5f5f5;
}

.sectionContainer2 {
  background-color: #f5f5f5;
  width: 100%;
  max-width: 1146px;
  margin: auto;
  padding-bottom: 50px;
}

.CoursSectionContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 65px !important;
  column-gap: 45px;
  background-color: #f5f5f5;
  padding-bottom: 30px;
}

.CoursSection {
  width: 352px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 27px;
}
.CoursSection .coursImg {
  width: 352px;
  border-radius: 8px;
  height: 210px;
}

.coursCartInfo {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.coursCartInfoTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coursTitle {
  font-size: 14px;
  font-weight: 700;
  color: #6f6565;
  line-height: 19px;
  width: max-content;
}
.coursCartInfoTitleLessonQuantity {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.coursCartInfoTitleQuantity {
  display: flex;
  gap: 2px;
  align-items: center;
}

.course-icon {
  width: 18px !important;
  height: 18px !important;
  fill: #e2aa18 !important;
}

.coursCartInfoTitleQuantity h1 {
  font-size: 12px;
  color: #000000;
  font-weight: 400;
  margin-top: 3px;
}

.sectionDescription {
  font-size: 12px;
  margin-top: 18px;
  margin-bottom: 22px;
  line-height: 16px;
  font-weight: 400;
  color: #7e7171;
  text-align: justify;
}

.seeMoreLink {
  text-decoration: none;
}

.seeMore {
  color: rgb(167, 167, 192);
  cursor: pointer;
  width: 70px;
  font-size: 20px;
  cursor: pointer;
}

.allCoursContainer {
  background: #f5f5f5;
}

/* Consultation Section */

.ConsultationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  overflow: hidden;
  padding: 0 300px;
}
.UnderBackgroundImgsContainer {
  margin-top: -3px;
}
.UnderBackgroundImgsContainer img {
  width: 100%;
}

.ConsultationMainImg img {
  margin-top: 110px;
}

.ConsultationPageInformation {
  width: 100%;
}

.ConsultationPageInformationContact {
  margin-bottom: 60px;
}

.ConsultationPageInformationTitle {
  color: #e2aa18;
  letter-spacing: 2%;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
}

.ConsultationPageInformationDescription {
  color: #7e7171;
  letter-spacing: 2%;
  font-size: 12px;
  font-weight: 300;
  padding: 14px 0px 54px 0px;
}

.ConsultationsInfoCont {
  display: flex;
  width: max-content;
  align-items: center;
  gap: 13px;
}
.ConsultIcon {
  width: 40px !important;
  height: 40px !important;
  padding: 7px;
  border: 2px solid #0f0672;
  color: #0f0672;
  border-radius: 50%;
}
.emailcontInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.ConsultationPageInformationContact {
  display: flex;
  gap: 9px;
}

.ConsultationsTitl {
  color: #7d6f6f;
  letter-spacing: 2%;
  font-size: 12px;
  text-align: start;
}
.ConsultationsDes {
  color: #7d6f6f;
  letter-spacing: 2%;
  font-size: 12px;
  font-weight: 400;
}

/* Students Swiper Section */

.students-wrapper {
  max-width: 100%;
  width: 100%;
  height: 662px;
  background-image: linear-gradient(
    to right,
    #0e223d,
    #1c304f,
    #2a3f61,
    #384e74,
    #465e88,
    #465e88,
    #465e88,
    #465e88,
    #384e74,
    #2a3f61,
    #1c304f,
    #0e223d
  );
  border: 1px solid #000000;
  margin: 0 auto;
}

.students-title {
  text-align: center;
  color: #e2aa18;
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 30px;
  margin-top: 39px;
}

.swiper-container {
  position: static !important;
  margin-top: 61px;
}

.swiper-slide {
  width: 550px !important;
  height: 415px !important;
  border: 2px solid #fffdfd;
  border-radius: 15px;
  position: static !important;
  padding: 38px 16px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  box-shadow: 0 8px 2px -2px rgba(0, 0, 0, 25%);
}

.swiper-slide * {
  background-image: none !important;
}

.students-card-title {
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.students-card-description {
  color: white;
  font-size: 16px;
  letter-spacing: 0.02em;
  margin-top: 24px;
  text-align: justify;
}

.student-photo {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  margin-right: 16px;
}

.students-person-wrapper {
  display: flex;
  width: 100%;
}

.student-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}

.student-name {
  color: #fffdfd;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}

.student-work {
  color: #fffdfd;
  font-size: 14px;
  letter-spacing: 1px;
}

.slider-controler {
  margin-top: 42px;
  gap: 42px;
  display: flex;
  justify-content: center;
}

.slider-arrow {
  cursor: pointer;
  color: #fffdfd;
}

.mobile-swiper {
  display: none !important;
}

/* Decide Course Section */

.DecideContainer {
  width: 100%;
  height: 177px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 140px;
}
.DecideContainer h1 {
  color: #e2aa18;
  line-height: 50px;
  letter-spacing: 2%;
  font-size: 30px;
  font-weight: 700;
}

/* Footer Styles */

footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 171px 140px 20px 140px;
  align-items: center;
  background-image: url("../../../Assets/Images/footer-background.png");
  background-size: 100% 100%;
}

.leftContainer {
  display: flex;
  gap: 120px;
  margin-top: 50px;
}
.mainH1 {
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 19.04px;
  letter-spacing: 2%;
  padding-bottom: 2px;
  font-size: 24px;
  margin-bottom: 15px;
}

.footerSection p {
  font-size: 14px;
  color: #7e7171;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}

.footerSection p::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  width: 0;
  height: 3px;
  background-color: #7e7171;
  transition: width 0.3s ease-out, left 0.3s ease-out;
}

.footerSection p:hover::before {
  width: calc(100%);
  left: 0px;
  bottom: -8px;
}
.footerSection p a {
  text-decoration: none;
  color: #7e7171;
}
.leftContainerInfo {
  display: flex;
  gap: 120px;
}
.footerInput {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.footerSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
}
form {
  width: 295px;
  height: 48px;
  display: flex;
  border: 1px solid rgba(229, 231, 233, 1);
  border-radius: 10px;
}

.footerInputContainer form button {
  width: 100%;
  border-radius: 0px 10px 10px 0px;
  border: none;
  height: 100%;
  color: rgba(255, 253, 253, 1);
  letter-spacing: 2%;
  font-weight: 400;
  font-size: 16px;
  background-image: linear-gradient(
    to right,
    #160e3d,
    #181845,
    #1a224d,
    #1e2c54,
    #23355b,
    #283c62,
    #2e4468,
    #344b6f,
    #3b5278,
    #415981,
    #48608a,
    #4f6793
  );
  padding-top: 4px;
  cursor: pointer;
}

.footerInputContainer form button:hover {
  color: #e2aa18;
}

.footerInputContainer form input {
  border-radius: 10px 0px 0px 10px;
  padding-left: 25px;
  padding-top: 4px;
  border: none;
  outline: none;
  font-size: 16px;
}

.footerInputContainer form input::placeholder {
  color: #000000;
  font-size: 16px;
}

.rightContainer {
  display: flex;
  gap: 9px;
  margin-top: -70px;
}
.rightContainer .facebookLogo {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.facLogo {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #e2aa18;
}
