@media (max-width: 889px) {
  .CoursDescriptionContainerContainer {
    flex-direction: column;
    gap: 100px;
    margin-bottom: 100px;
  }
  .CoursDetalRecentagle {
    font-size: 16px;
  }

  .coursCostContainer {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }

  .coursCostContainer div {
    width: 300px;
  }
}

@media screen and (max-width: 800px) {
  .coursCostContainer {
    width: auto;
    gap: 10px;
    margin: 0 auto;
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .CoursDescriptionContainerContainer {
    margin: 0 30px;
    margin-top: 130px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 500px) {
  .course-register-form-title {
    font-size: 26px;
  }

  .course-register-form-close {
    width: 70px;
    height: 70px;
  }

  .course-register-form-close * {
    width: 30px !important;
    height: 30px !important;
  }
}

@media screen and (max-width: 400px) {
  .CoursDescriptionContainerContainer {
    margin: 0 15px;
    margin-top: 130px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 368px) {
  .course-register-form-close {
    width: 50px;
    height: 50px;
  }

  .course-register-form-close * {
    width: 20px !important;
    height: 20px !important;
  }
}

@media screen and (max-width: 350px) {
  .CoursDescriptionContainerContainer {
    margin: 0 5px;
    margin-top: 130px;
    margin-bottom: 100px;
  }
}
