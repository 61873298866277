@media screen and (max-width: 1600px) {
  .eduschool-main {
    padding: 0 150px;
  }
}

@media screen and (max-width: 1250px) {
  .mentor-swiper-title {
    font-size: 22px;
  }

  .eduschool-course-prices {
    min-width: auto;
  }

  .eduschool-goal {
    margin-top: -250px;
  }

  .eduschool-goal-title {
    font-size: 24px;
  }

  .eduschool-goal-description {
    font-size: 18px;
  }

  .educity-additional-module {
    height: 72px;
    font-size: 26px;
  }
}

@media screen and (max-width: 1100px) {
  .eduschool-main {
    padding: 0 100px;
  }

  .mentor-swiper-title {
    margin-top: 5px;
    font-size: 20px;
  }

  .eduschool-courseinfo-mentor2 {
    gap: 44px;
  }

  .eduschool-goal {
    min-height: 300px;
    margin-top: -180px;
  }

  .eduschool-goal-title {
    font-size: 20px;
  }

  .eduschool-goal-description {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .eduschool-main {
    padding: 0 60px;
  }
}

@media screen and (max-width: 800px) {
  .eduschool-main {
    padding: 0 20px;
  }

  .mentor-swiper-title {
    margin-top: 5px;
    font-size: 16px;
  }

  .eduschool-goal-title {
    font-size: 18px;
  }

  .eduschool-goal-description {
    font-size: 14px;
  }

  .educity-additional-modules > div {
    gap: 20px;
  }

  .educity-additional-module {
    height: 62px;
    font-size: 22px;
  }
}

@media screen and (max-width: 700px) {
  .eduschool-courseinfo-mentor {
    flex-direction: column-reverse;
  }

  .eduschool-courseinfo-mentor2 {
    flex-direction: column;
  }

  .mentor-swiper-title {
    font-size: 25px;
    text-align: center;
    min-width: 0;
  }

  .eduschool-swiper-container {
    margin: 0 auto;
  }

  .eduschool-course-prices {
    align-items: center;
  }

  .eduschool-course-title {
    text-align: center;
  }

  .eduschool-course-priceinfo {
    gap: 25px;
  }

  .eduschool-goal {
    margin-top: 0;
    border: 0;
  }

  .eduschool-buttons-wrapper {
    flex-direction: column;
    align-self: center;
  }

  .educity-additional-modules-title {
    font-size: 28px;
  }

  .educity-additional-modules {
    flex-direction: column;
    gap: 10px;
  }

  .educity-additional-modules > div {
    gap: 10px;
  }

  .educity-additional-module {
    padding: 0;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .educity-additional-modules-title {
    font-size: 25px;
  }

  .educity-additional-module {
    height: 52px;
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .educity-additional-modules-title {
    font-size: 22px;
  }

  .eduschool-main {
    padding: 0 5px;
  }

  .eduschool-goal-title {
    font-size: 16px;
  }

  .eduschool-goal-description {
    font-size: 12px;
    margin-top: 25px;
  }

  .educity-additional-module {
    height: 52px;
    font-size: 15px;
  }
}
