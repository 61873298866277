@import url("https://fonts.cdnfonts.com/css/bpg-nino-mtavruli");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "BPG Nino Mtavruli", sans-serif;
}

.CoursDescriptionContainerContainer {
  display: flex;
  gap: 36px;
  margin: 150px 86px 0px 86px;
}

.coursCostContainer {
  width: 306px;
  height: max-content;
  display: flex;
  flex-direction: column;
}

.coursCostContainer img {
  width: 313px;
  border-radius: 10px;
  margin-left: -3px;
  border: 1px solid black;
}
.lectorName {
  font-weight: 700;
  font-size: 20px;
  line-height: 23.05px;
  text-align: center;
  padding: 23px 0px 12px 0px;
}
.coursTime {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.coursTimeCont {
  width: 93px;
  height: 61px;
  border-radius: 10px;
  border: 1px solid rgba(44, 67, 102, 0.3);
  padding-top: 11px;
}
.coursTimeCont h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 2%;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  align-items: center;
  margin-top: 2px;
}
.CoursCostH2 {
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
}
.CoursCostCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}
.CoursCostCont h2 {
  font-size: 16px;
  font-weight: 700;
  color: rgba(226, 170, 24, 1);
}
.CursCostBtn {
  width: 306px;
  height: 52px;
  font-size: 18px;
  letter-spacing: 2%;
  padding-top: 4px;
  border-radius: 15px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  border: 1px solid rgba(44, 67, 102, 0.3);
  color: rgba(226, 170, 24, 1);
  background-color: rgba(255, 255, 255, 1);
  margin-top: 18px;
}

.CoursDetalContainer {
  width: 100%;
}
.CoursDetalRecentagle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 543px;
  background: linear-gradient(
    90deg,
    #0e223d 0%,
    #2b4264 49.05%,
    #4f6793 93.05%
  );
  border-radius: 10px;
}

.CoursDetalRecentagle h2 {
  text-align: center;
  color: rgba(226, 170, 24, 1);
  font-size: 40px;
  line-height: 46px;
  font-weight: 700;
  padding: 0px 10px 0px 10px;
}

.CoursDetalDescription {
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 23px;
}
.CoursDetalDescriptionCont {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.CoursDetalDescriptionCont h2 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}
.CoursDetalDescriptionCont p,
.CoursDetalDescriptionCont ul li {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
}
.CoursDetalDescriptionCont ul {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
