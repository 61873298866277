@media screen and (max-width: 1600px) {
  /* Navbar Responsive Styles */

  .navbar-wrapper {
    padding: 15px 180px;
  }

  /* Header description and photo responsive styles */

  .header-info-margin-container {
    margin: 0 180px;
    margin-top: 200px;
  }
}

@media (min-width: 1538px) {
  /* Popular Courses section responsive styles */

  .sectionContainer2 {
    width: 1440px;
  }
  .CoursSectionContainer {
    grid-template-columns: auto auto auto;
    gap: 30px;
  }
}

@media screen and (max-width: 1440px) {
  /* Navbar Responsive Styles */

  .navbar-wrapper {
    padding: 15px 146px;
  }

  .navbar-logo {
    max-width: 200px;
    height: 80px;
  }

  .navbar-link-wrapper li a {
    font-size: 16px;
  }

  /* Header description and photo responsive styles */

  .header-info-margin-container {
    margin: 0 146px;
    max-width: 1000px;
    margin-top: 200px;
  }

  .header-info-image {
    margin-right: 50px;
  }
}

@media screen and (max-width: 1300px) {
  /* Navbar Responsive Styles */

  .navbar-wrapper {
    padding: 15px 120px;
  }

  /* Footer responsive styles */

  footer {
    flex-direction: column;
  }
  .rightContainer {
    margin-top: -40px;
    order: 1;
    padding-bottom: 65px;
  }
  .leftContainer {
    order: 2;
  }
}

@media screen and (max-width: 1200px) {
  /* Navbar Responsive Styles */

  .navbar-wrapper {
    padding: 15px 60px;
  }

  .navbar-link-wrapper {
    gap: 15px;
  }

  /* Header description and photo responsive styles */

  .header-info-wrapper {
    height: 547px;
    background-image: url("../../../Assets/Images/header-background.png");
    background-size: 100% 80%;
    background-attachment: fixed;
  }

  .header-info-margin-container {
    margin: 0 100px;
    max-width: 90%;
    margin-top: 200px;
  }

  .header-info-text-wrapper {
    max-width: 700px;
  }

  .header-info-title {
    font-size: 24px;
  }

  /* partners animation responsive styles */

  .partnerIconContainer {
    animation: scrollRightToLeft 4s linear infinite;
  }

  /* Popular Course Section responsive styles */

  .CoursSectionContainer {
    grid-template-columns: auto auto;
  }
  .sectionContainer2 {
    width: 960px;
  }
  .CoursSection .coursImg {
    width: 456px;
    height: 300px;
  }
  .CoursSection {
    width: 100%;
  }

  /* Consultation section responsive styles */

  .ConsultationContainer {
    flex-direction: column;
    text-align: center;
    gap: 20px;
    padding: 0 15px;
  }

  .ConsultationMainImg img {
    width: 100%;
  }

  .ConsultationPageInformationContact {
    margin-bottom: 40px;
  }

  .ConsultationPageInformationTitle {
    text-align: center;
  }

  .ConsultationPageInformationDescription {
    text-align: center;
  }

  .consultation-button {
    margin-bottom: 80px;
  }

  .ConsultationPageInformationContact {
    justify-content: center;
  }

  /* Decide Section responsive styles */

  .DecideContainer h1 {
    font-size: 15px;
  }
  .DecideContainer button {
    height: 40px;
  }
}

@media screen and (max-width: 1000px) {
  /* Navbar Responsive Styles */

  .navbar-wrapper {
    padding: 15px 30px;
  }

  .navbar-logo {
    max-width: 180px;
    height: 70px;
  }

  .navbar-link-wrapper li a {
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
  }

  /* Header description and photo responsive styles */

  .header-info-margin-container {
    margin: 0 30px;
    max-width: 90%;
    margin-top: 200px;
  }

  .header-info-image {
    display: none;
  }

  /* Popular Course Section responsive styles */

  .CoursSectionContainer {
    grid-template-columns: auto auto;
    row-gap: 25px;
    column-gap: 45px;
  }
  .CoursSection {
    margin: auto;
  }
  .sectionContainer2 {
    width: 720px;
  }
  .CoursSection {
    width: 100%;
  }
  .CoursSection .coursImg {
    width: 100%;
  }
}

@media screen and (max-width: 871px) {
  /* Decide Section responsive styles */

  .DecideContainer {
    justify-content: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  /* Navbar Responsive Styles */

  .navbar-link-wrapper {
    display: none;
    z-index: 20 !important;
  }

  .navbar-mobile-link-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100vh;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 35px;
    list-style-type: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-top: 40px;
  }

  .navbar-mobile-link-wrapper li a {
    color: #302c2c;
    letter-spacing: 0.32px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
  }

  .navbar-mobile-link-wrapper li a:hover {
    color: #2c4366;
  }

  .navbar-mobile-link-wrapper li::after {
    display: block;
    content: "";
    width: 100%;
    height: 3px;
    background-color: #e2aa18;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    margin-top: 8px;
  }

  .navbar-mobile-link-wrapper li:hover:after {
    transform: scaleX(1);
  }

  .navbar-social-icons > * {
    visibility: hidden;
  }

  .mobile-menu-icon {
    display: block !important;
    z-index: 100;
  }
}

@media screen and (max-width: 800px) {
  /* partners animation responsive styles */

  .partnerIconContainer {
    animation: scrollRightToLeft 10s linear infinite;
  }

  /* Footer responsive styles */

  .leftContainer {
    flex-direction: column;
    gap: 40px;
  }
  .rightContainer {
    order: 1;
    padding-bottom: 40px;
  }
  .leftContainerInfo {
    order: 2;
  }
  .leftContainerInput {
    text-align: center;
    margin: auto;
    order: 1;
  }
}

@media screen and (max-width: 768px) {
  /* Popular Course Section responsive styles */

  .sectionContainer2 {
    max-width: 540px;
    width: 100%;
    padding: 0 15px;
    padding-bottom: 50px;
  }
  .CoursSectionContainer {
    grid-template-columns: auto;
  }

  /* Consultation Section responsive styles */

  .ConsultationButton {
    flex-direction: column;
    width: max-content;
    margin: auto;
  }
  .ConsultationPageInformationContact {
    flex-direction: column;
    width: max-content;
    margin: 0 auto;
    margin-bottom: 40px;
    gap: 30px;
  }
  .ConsultationsInfoCont {
    width: 350px;
    border: 1px solid;
    padding: 10px 30px;
    border-radius: 10px;
    border: 2px solid #385076;
  }
}

@media screen and (max-width: 600px) {
  /* Students swiper section responsive styles */

  .students-title {
    font-size: 25px;
  }

  .swiper-container {
    display: none !important;
  }

  .mobile-swiper {
    display: block !important;
    margin: 100px auto;
    margin-top: 60px;
    max-width: 550px;
    height: 440px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    background: #2a3f61;
  }

  /* Decide Section responsive styles */

  .DecideContainer h1 {
    text-align: center;
    line-height: 20px;
    margin-bottom: 14px;
    letter-spacing: 1%;
  }
}

@media screen and (max-width: 550px) {
  /* Navbar Responsive Styles */

  .navbar-mobile-link-wrapper {
    width: 80%;
  }

  /* partners animation responsive styles */

  .partnerIconContainer {
    animation: scrollRightToLeft 1.3s linear infinite;
  }

  /* Consultation Section responsive styles */

  .ConsultationPageInformationTitle {
    font-size: 24px;
  }

  .swiper-slide {
    max-width: 100%;
  }

  /* Students Section responsive styles */

  .students-card-description {
    font-size: 14px;
  }

  /* Decide Section responsive styles */

  .DecideContainer {
    padding: 0 15px;
  }
}

@media screen and (max-width: 420px) {
  /* Navbar Responsive Styles */

  .navbar-wrapper {
    padding: 15px;
  }

  .navbar-social-icons {
    position: absolute;
  }

  /* Header description and photo responsive styles */

  .header-info-margin-container {
    margin: 0 15px;
    margin-top: 200px;
  }

  /* Consultation Section responsive styles */

  .ConsultationsInfoCont {
    max-width: 350px;
    width: 100%;
  }

  /* Students Section responsive styles */

  .students-card-description {
    font-size: 11px;
  }

  /* Footer responsive styles */

  .leftContainerInfo {
    margin: 0 auto;
    flex-direction: row;
    text-align: center;
    gap: 40px;
  }
}
