* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.all-courses-header-background {
  width: 100%;
  height: 152px;
  background-image: url("../../../Assets/Images/contacts-header-background.png");
  background-size: 100% 100%;
  margin-bottom: 50px;
}

.transparent {
  background: none !important;
}

.children-program-title {
  color: #e2aa18;
  font-size: 32px;
  margin: 70px 0;
}
