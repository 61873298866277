@import url("https://fonts.cdnfonts.com/css/bpg-nino-mtavruli");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "BPG Nino Mtavruli", sans-serif;
}

.BusinesContainer1 {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 71px;
  gap: 50px;
  align-items: center;
  padding: 0px 96px 0px 96px;
}

.BusinesContainer1Cont1Img {
  width: 100%;
  height: 403px;
  max-width: 562px;
}
.BusinesContainer1Cont1Img img {
  width: 100%;
  height: 100%;
}
.BusinesContainer1Cont1 {
  text-align: center;
  width: 100%;
  max-width: 710px;
}
.BusinesContainer1Cont1 h1 {
  color: rgba(226, 170, 24, 1);
  font-weight: 700;
  font-size: 38px;
  letter-spacing: 2%;
  line-height: 43px;
  max-width: 710px;
}
.BusinesContainer1Cont1 p {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 2%;
  max-width: 710px;
  margin-top: 35px;
}
/* ////////////////////////////// */
.BusinesContainer2 {
  padding: 0px 96px 0px 96px;
}
.BusinesContainer2 h1 {
  color: rgba(226, 170, 24, 1);
  letter-spacing: 2%;
  text-align: center;
  font-size: 38px;
  font-weight: 700;
}
.BusinesContainer2 p {
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2%;
  margin-bottom: 54px;
}
.BusinesContainer2 {
  margin-top: 71px;
  text-align: center;
  margin-bottom: 100px;
  width: 100%;
  padding: 27px 0px 124px 0px;
}

.BusinesContainer2SponsoredContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 96px 0px 96px;
  justify-content: space-between;
}
.BusinesContainer2SponsoredContainer img {
  width: 175px;
  margin: 10px;
  height: 157px;
}

.business-partners {
  background-color: #f5f5f5;
}

/* ////////////////////////////////////// */
.BusinesContainer3 {
  margin-top: 50px;
  padding: 0px 96px 0px 96px;
}
.becomePartner {
  color: rgba(226, 170, 24, 1);
  font-size: 38px;
  letter-spacing: 2%;
  line-height: 43px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 77px;
}
.BusinesContainer3Container {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.BusinesContainer3InfoCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 77px;
}
.BusinesContainer3InfoContBtn {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.BusinesContainer3InfoCont p {
  max-width: 720px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  letter-spacing: 2%;
  line-height: 30px;
  font-size: 30px;
  font-weight: 400;
}

/* ////////////////////// */
.BusinesContainer3InputCont {
  width: 711px;
  height: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.BusinesContainer3InputContNameUsername input {
  width: 100%;
}
.BusinesContainer3InputContNameUsername {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.BusinesContainer3InputContInput {
  display: flex;
  gap: 29px;
}

.BusinesContainer3InputContInputs {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  padding: 22px 0px 22px 14px;
  border-radius: 15px;
  outline: none;
}

input::placeholder {
  color: #000000;
}

.BusinesContainer3InputContTextInp {
  height: 214px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  padding-bottom: 150px;
  font-size: 18px;
  outline: none;
}

.BusinesContainer3InputContTextInp input {
  width: calc(
    100% - 28px
  ); /* Calculating width with margins taken into account */
  margin: 22px 14px;
  line-height: 18px;
  font-weight: 400;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
  border: none;
}

.BusinesContainer3InputContTextInp input:focus {
  outline: none; /* Removing the default focus outline */
}

.BusinessInfoSendBtnCont {
  display: flex;
  justify-content: right;
}

@media (max-width: 1587px) {
  .BusinesContainer2SponsoredContainer {
    gap: 50px;
  }
}

@media (max-width: 938px) {
  .BusinesContainer1 {
    flex-direction: column;
    padding: 0;
  }
  .BusinesContainer1Cont1 {
    order: 2;
  }
  .BusinesContainer1Cont1Img {
    order: 1;
  }
  .BusinesContainer1Cont1Img img {
    height: 403px;
  }
}

@media (max-width: 1100px) {
  .BusinesContainer3InfoContBtn {
    justify-content: center;
  }
}
@media screen and (min-width: 1101px) and (max-width: 1415px) {
  .BusinesContainer3InfoCont p {
    text-align: left;
  }
}
@media (max-width: 632px) {
  .BusinesContainer2SponsoredContainer {
    justify-content: center;
  }
}
@media (max-width: 1100px) {
  .BusinesContainer3Container {
    display: block;
  }
  .BusinesContainer3InfoCont {
    gap: 30px;
    margin-bottom: 70px;
  }
  .BusinesContainer3InfoCont p {
    color: rgba(0, 0, 0, 1);
    margin: auto;
  }
  .BusinesContainer3InputCont {
    margin: auto;
    width: auto;
  }
  .BusinesContainer3InputContNameUsername input {
    width: 100%;
  }
}

@media screen and (min-width: 876px) and (max-width: 1121px) {
  .BusinesContainer2SponsoredContainer {
    justify-content: flex-start;
  }
}

@media (max-width: 1367px) {
  .BusinesContainer2 {
    margin-bottom: 0px;
  }
}
@media (max-width: 782px) {
  .BusinesContainer2,
  .BusinesContainer3,
  .BusinesContainer2SponsoredContainer {
    padding: 0px 20px 0px 20px;
  }
  .BusinesContainer3 {
    margin-top: 100px;
  }
}
