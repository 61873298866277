@import url("//cdn.web-fonts.ge/fonts/bpg-nino-mtavruli/css/bpg-nino-mtavruli.min.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "BPG Nino Mtavruli", sans-serif;
}

.contacts-header-background {
  width: 100%;
  height: 152px;
  background-image: url("../../../Assets/Images/contacts-header-background.png");
  background-size: 100% 100%;
  margin-bottom: 50px;
}

.form-main-wrapper {
  display: flex;
  gap: 100px;
  max-width: 1315px;
  width: 100%;
  height: 712px;
  margin: 0 auto;
  margin-bottom: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px 0,
    rgba(0, 0, 0, 0.15) -1.95px -1.95px 2.6px 0;
  border-radius: 10px;
}

.contact-info-wrapper {
  max-width: 483px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    #160e3d,
    #181845,
    #1a224d,
    #1e2c54,
    #23355b,
    #283c62,
    #2e4468,
    #344b6f,
    #3b5278,
    #415981,
    #48608a,
    #4f6793
  );
  border-radius: 10px;
  padding: 46px 37px;
}

.contact-info-title {
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #e2aa18;
  letter-spacing: 0.32px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 33px;
  margin-top: 43px;
}

.contact-each-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact-icon {
  color: #e2aa18;
  width: 30px !important;
  height: 30px !important;
}

.contact-info-address {
  color: #ffffff;
  letter-spacing: 0.32px;
}

.form-registration {
  max-width: 621px;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border: none;
  margin-top: 28px;
}

.form-registration input::placeholder,
textarea::placeholder {
  color: #000000;
}

.form-name-email-wrapper {
  width: 100%;
  display: flex;
  gap: 27px;
}

.name-input {
  width: 297px;
  height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

.email-input {
  width: 297px;
  height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

.number-input {
  max-width: 621px;
  height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

.about-us-input {
  max-width: 621px;
  height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

.which-course-input {
  max-width: 621px;
  height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

.why-input {
  max-width: 621px;
  height: 214px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  padding-bottom: 150px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

@media screen and (max-width: 1000px) {
  .form-registration {
    margin: 0;
    margin-bottom: 50px;
  }

  .why-input {
    height: 65px;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 700px) {
  .form-registration {
    max-width: 100%;
    padding: 0 15px;
    margin: 0;
    margin-bottom: 50px;
    gap: 15px;
  }
}

@media screen and (max-width: 450px) {
  .form-name-email-wrapper {
    flex-direction: column;
    gap: 15px;
  }

  .name-input,
  .email-input {
    max-width: 100%;
    width: 100%;
  }

  .form-registration {
    margin: 0;
    margin-bottom: 50px;
  }
}
