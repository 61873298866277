@import url("https://fonts.cdnfonts.com/css/bpg-nino-mtavruli");

* {
  font-family: "BPG Nino Mtavruli", sans-serif;
}

.AboutUsCont1 {
  display: flex;
  padding: 0px 80px;
  align-items: center;
  gap: 60px;
}
.AboutUsCont1Info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}
.AboutUsCont1Info h1 {
  font-size: 38px;
  letter-spacing: 2%;
  line-height: 43px;
  font-weight: 700;
  color: rgba(226, 170, 24, 1);
}
.AboutUsCont1Info p {
  font-size: 20px;
  line-height: 20.02px;
  letter-spacing: 2%;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
.AboutUsCont1ImgContainer {
  max-width: 100%;
  display: flex;
  justify-content: space-between !important;
}

.htmlImage {
  max-width: 471px;
}

.AboutUsCont2 {
  margin-top: 63px;
  display: flex;
  padding: 37px 80px;
  background-color: rgba(245, 245, 245, 1);
  justify-content: space-between;
  gap: 25px;
}
.AboutUsCont2Cont1 {
  width: 381px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  border: 1px solid;
  padding: 22px 20px;
  background: linear-gradient(
    90deg,
    #0e223d 0%,
    #2b4264 49.05%,
    #4f6793 93.05%
  );
}
.AboutUsCont2Cont1H1,
.AboutUsCont2Cont1H2 {
  font-weight: 700;
  letter-spacing: 2%;
  color: rgba(226, 170, 24, 1);
}
.AboutUsCont2Cont1H1 {
  font-size: 48px;
  line-height: 55.31px;
}
.AboutUsCont2Cont1P {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 2%;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.AboutUsCont2Cont1H2 {
  font-size: 20px;
  line-height: 24.05px;
}

/* /////////////////// */

.AboutUsCont3 {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  align-items: center;
  justify-content: center;
  margin: 30px;
  margin-top: 96px;
}
.AboutUsCont3Cont1 {
  max-width: 500px;
  display: flex;
  gap: 16px;
  max-width: 100%;
}

.AboutUsCont3Cont1Img {
  max-width: 383px;
  width: 100%;
  height: 100%;
}

.AboutUsCont3Cont1Imgs {
  max-width: 367px;
  width: 100%;
}

.AboutUsCont3Cont1Cont1 {
  display: flex;
  width: 100%;
  gap: 16px;
  flex-direction: column;
}

.AboutUsCont3Cont2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  max-width: 500px;
}
.AboutUsCont3Cont2 h1 {
  line-height: 32.27px;
  letter-spacing: 2%;
  font-weight: 700;
  font-size: 28px;
  color: rgba(226, 170, 24, 1);
}
.AboutUsCont3Cont2 p {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 2%;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

@media screen and (max-width: 820px) {
  .AboutUsCont3Cont1 {
    flex-direction: column;
  }

  .AboutUsCont3Cont1Imgs {
    max-width: 100%;
    height: 360px;
  }
}

@media screen and (max-width: 768px) {
  .AboutUsCont3 {
    padding: 0px 20px;
  }

  .AboutUsCont3Cont1 {
    max-width: 100%;
  }

  .AboutUsCont3Cont2 {
    max-width: 100%;
  }
}

/* @media (max-width: 460px) {
    .htmlImage {

    }
} */
@media (max-width: 1059px) {
  .AboutUsCont1 {
    flex-direction: column;
    padding: 0;
  }
  .AboutUsCont1Info {
    order: 2;
    padding: 0px 80px;
    margin-top: -30px;
    gap: 20px;
  }
  .AboutUsCont1ImgContainer {
    order: 1;
  }
}
@media (max-width: 830px) {
  .AboutUsCont1Info,
  .DecideContainer {
    padding: 0px 25px;
  }
  .AboutUsCont2 {
    padding: 37px 25px;
  }
}
@media (max-width: 747px) {
  .AboutUsCont2 {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .htmlImage {
    max-width: 100%;
  }
}
