@import url("//cdn.web-fonts.ge/fonts/bpg-nino-mtavruli/css/bpg-nino-mtavruli.min.css");

.page-titles-wrapper {
  max-width: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #160e3d,
    #181845,
    #1a224d,
    #1e2c54,
    #23355b,
    #283c62,
    #2e4468,
    #344b6f,
    #3b5278,
    #415981,
    #48608a,
    #4f6793
  );
  margin-top: 140px;
  padding: 71px 0;
}

.page-title {
  color: #ffffff;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 30px;
  letter-spacing: 0.32px;
  text-align: center;
}

.orange {
  color: #e2aa18;
}
