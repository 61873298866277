@import url("https://fonts.cdnfonts.com/css/bpg-nino-mtavruli");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "BPG Nino Mtavruli", sans-serif;
}

.course-register-form-wrapper {
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.course-register-form-wrapper::-webkit-scrollbar {
  display: none;
}

.course-register-form-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.course-register-form-header div {
  visibility: hidden;
}

.course-register-form-title {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0%;
  align-self: flex-end;
}

.course-register-form-close {
  width: 118px;
  height: 83px;
  border: 0;
  background-image: linear-gradient(
    to right,
    #160e3d,
    #181845,
    #1a224d,
    #1e2c54,
    #23355b,
    #283c62,
    #2e4468,
    #344b6f,
    #3b5278,
    #415981,
    #48608a,
    #4f6793
  );
  box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  -webkit-box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  -moz-box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  border-bottom-left-radius: 10px;
  color: white;
  cursor: pointer;
}

.course-register-form-close:hover {
  color: #e2aa18;
}

.course-register-form-close * {
  width: 58px !important;
  height: 43px !important;
}

.form-registration {
  max-width: 621px;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border: none;
  margin-top: 28px;
}

.form-registration input::placeholder,
textarea::placeholder {
  color: #000000;
}

.form-name-email-wrapper {
  width: 100%;
  display: flex;
  gap: 27px;
}

.name-input {
  width: 297px;
  min-height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

.email-input {
  width: 297px;
  min-height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

.number-input {
  max-width: 621px;
  min-height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

.about-us-input {
  max-width: 621px;
  min-height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

.which-course-input {
  max-width: 621px;
  min-height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

.lesson-amount-input {
  max-width: 621px;
  min-height: 65px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 8px;
  outline: none;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.lesson-amount-input li {
  width: 100%;
  cursor: pointer;
}

.lesson-amount-input select {
  border: none;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
  width: 100%;
  cursor: pointer;
}

.why-input {
  max-width: 621px;
  height: 170px;
  min-height: 170px;
  border: 1px solid rgba(44, 67, 103, 0.5);
  border-radius: 15px;
  padding: 0 12px;
  padding-bottom: 105px;
  font-family: "BPG Nino Mtavruli", sans-serif;
  font-size: 18px;
  outline: none;
}

@media screen and (max-width: 1000px) {
  .form-registration {
    margin: 0;
    margin-bottom: 50px;
  }

  .why-input {
    height: auto;
    min-height: 65px;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 700px) {
  .form-registration {
    max-width: 100%;
    padding: 0 15px;
    margin: 0;
    margin-bottom: 50px;
    gap: 15px;
  }
}

@media screen and (max-width: 450px) {
  .form-name-email-wrapper {
    flex-direction: column;
    gap: 15px;
  }

  .name-input,
  .email-input {
    max-width: 100%;
    width: 100%;
  }

  .name-input,
  .email-input,
  .number-input,
  .about-us-input,
  .which-course-input,
  .lesson-amount-input,
  .why-input {
    height: auto;
    min-height: 40px;
    font-size: 15px;
  }

  .lesson-amount-input select {
    font-size: 15px;
  }

  .form-registration {
    margin: 0;
    margin-bottom: 50px;
  }

  .course-register-form-title {
    font-size: 25px;
  }

  .course-register-form-close {
    width: 60px;
    height: 60px;
  }
}
