@media screen and (max-width: 1000px) {
  .form-main-wrapper {
    flex-direction: column;
    height: auto;
    align-items: center;
    gap: 50px;
  }

  .contact-info-wrapper {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-info-title {
    text-align: center;
  }

  .contact-info {
    flex-direction: row;
    gap: 50px;
  }
}

@media screen and (max-width: 620px) {
  .contact-info {
    flex-direction: column;
  }
}
