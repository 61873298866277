.main-button {
  background-image: linear-gradient(
    to right,
    #160e3d,
    #181845,
    #1a224d,
    #1e2c54,
    #23355b,
    #283c62,
    #2e4468,
    #344b6f,
    #3b5278,
    #415981,
    #48608a,
    #4f6793
  );
  border: none;
  border-radius: 15px;
  box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  -webkit-box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  -moz-box-shadow: 1px 4px 4px 1px rgba(117, 101, 101, 0.79);
  font-family: "BPG Nino Mtavruli", sans-serif;
  color: #ffffff;
  letter-spacing: 0.32px;
  padding-top: 4px;
  cursor: pointer;
}

.main-button:hover {
  color: #e2aa18;
}
